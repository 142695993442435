import React from 'react';
import {ThemeProvider} from 'styled-components';


import themes from '../commonStyles/themes';
import { MainContainer, ThemeContainer } from '../commonStyles/Containers';

import SEO from '../components/seo';
import AllFixedElements from '../components/AllFixedElements';
import SectionFooter from '../components/SectionFooter';
import WorkComponent from '../components/WorkComponent';

import GlobalStyle from '../components/layoutCss';


class WorksPage extends React.PureComponent {

  render() {
    return (
      <>
        <GlobalStyle />

        <ThemeProvider theme={themes.themeMain}>
          <ThemeContainer>
            <SEO
              title='Works'
              path={this.props.path}
            />
            <AllFixedElements current={'works'} />
            <MainContainer>
              <WorkComponent />
              <SectionFooter />
            </MainContainer>
          </ThemeContainer>
        </ThemeProvider>
      </>
    );
  }
}
export default WorksPage;
